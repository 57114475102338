import { LDContext, identify } from '@sennder/senn-node-feature-flags-frontend'
import { getStateCompany, getStateUser } from '@/store'

export const identifyUserInLaunchDarkly = async () => {
  const user = getStateUser()
  const company = getStateCompany()

  const ldContext: LDContext = {
    kind: 'user',
    key: user.id.toString(),
    email: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    country: user.timezone,
    organisationType: 'carrier',
    organisationId: company.cpsCarrierId,
  }

  await identify(ldContext)
  console.info(
    '[orcas-mobile-shell - LaunchDarkly]: User is identified in LaunchDarkly'
  )
}

export const getLaunchDarklyAnonymousContext = (): LDContext => {
  return {
    key: 'user_key',
  }
}

export const anonymizeUserInLaunchDarkly = async () => {
  await identify(getLaunchDarklyAnonymousContext())
  console.info(
    '[orcas-mobile-shell - LaunchDarkly]: User is anonymized in LaunchDarkly'
  )
}
