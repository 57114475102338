import {
  GetTokenOptions,
  IAction,
  ICommonHeaders,
  IMicrofrontendData,
  IOrcasMobileSharedData,
  IUser,
  IUserCompany,
  IUserContact,
  OrgType,
  SharedDataType,
} from '@sennder/senn-node-microfrontend-interfaces'
import mothershipService from '@/services/mothershipService'
import { getAuth0Header, getAuth0Token } from '@/services/tokenManager'
import { getFeatureFlags } from '@sennder/senn-node-feature-flags-frontend'
import { reactive, watch } from 'vue'
import { logger } from '@/services/logger/loggers'
import { i18n } from '@/services/i18n'
import { logout } from '@/store/logoutActions'
import { getTenant } from '@/plugins/tenants'
import {
  getMarketplaceAccess,
  getOnboardingFormAccess,
} from '@/store/featuresHelper'
import errorsHandler from '@/services/errors-handler'
import carrierUserService from '@/services/carrierUserService'
import carrierProfilePublicService from '../services/carrierProfilePublicService'
import {
  Carrier,
  GPSIntegration,
} from '@sennder/carrier-profile-public-axios-client'
import { mapCUSToMothershipUserData } from '@/helpers/user'
import { ICarrier, deserialize } from '@/models'
import { Optional, Prettify } from '@/types/types'
import { notify } from './notify'
import { analytics } from '@/services/analyticsProvider'
import { ICUSUser } from '@/types/cus'
import { sendErrorInMonitor } from '@/services/monitor'
import { permissionService } from '@/services/permissionService'
import { AUTH0_API_AUDIENCE } from '@/common/config'
import { timedFn } from '@/performance'

export { loadUserData } from './userDataHelper'

const defaultTokenOptions: GetTokenOptions = {
  usePopup: false,
  throwException: false,
}

const getAuthToken = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Token(restOptions, usePopup, throwException)
}

const getAuthHeader = async (options = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Header(restOptions, usePopup, throwException)
}

async function getCommonHeaders(): Promise<ICommonHeaders> {
  if (!AUTH0_API_AUDIENCE) {
    throw new Error('AUTH0_API_AUDIENCE is not defined')
  }
  const { user, carrier } = getStateData()
  return {
    Authorization: await getAuth0Header({
      audience: AUTH0_API_AUDIENCE,
    }),
    'X-Org-Type': user ? OrgType.CARRIER : null,
    'X-Org-Id': carrier?.id ?? null,
  }
}

export type OrcasMobileExtendedData = {
  isDenylisted: boolean
  hasOrcasAccess: boolean
  hasSennpayAccess: boolean
  carrier?: ICarrier
  gpsIntegration?: GPSIntegration
  cpsCarrier: Carrier
}

export type OrcasMobileStoreData = Prettify<
  Optional<
    IOrcasMobileSharedData<OrcasMobileExtendedData>,
    'user' | 'company' | 'contact' | 'cpsCarrier'
  >
>

const getEmptyData = (): OrcasMobileStoreData => {
  return {
    type: SharedDataType.ORCAS_MOBILE,
    featureFlags: getFeatureFlags() || {},
    language: 'en',
    tenant: getTenant(),
    hasMarketplaceAccess: false,
    isDenylisted: false,
    hasOnboardingFormAccess: false,
    hasOrcasAccess: false,
    hasSennpayAccess: false,
  }
}

const store = reactive({
  state: {
    data: getEmptyData(),
    callbacks: {
      getToken: getAuthHeader,
      getAuthToken,
      getAuthHeader,
      syncParentRouter: () => {},
      // TODO: deprecate this callback when all mFs are updated
      segmentTrackEvent: analytics.trackEvent,
      onUnauthorized: async () => {
        try {
          await logout()
        } catch (error) {
          errorsHandler(
            error,
            '[orcas-mobile-shell - Store] Error during logout'
          )
        }
      },
      onUnhandledError: errorsHandler,
      getCommonHeaders,
      getPermissions: async (actions: IAction[]) =>
        permissionService.getPermissions(actions, await getCommonHeaders()),
    },
    providers: {
      logger,
      notifications: {
        error: (message: string) => notify(message, 'error'),
        success: (message: string) => notify(message, 'success'),
      },
      translations: {
        tc: i18n.global.tc,
        t: i18n.global.t,
      },
      // TODO: deprecate segment provider when all mFs are updated to use analytics provider
      segment: analytics,
      analytics,
      monitoring: {
        sendError: sendErrorInMonitor,
      },
    },
  } satisfies IMicrofrontendData<OrcasMobileStoreData>,
})

// watch feature flags changes in senn-node-feature-flags-frontend and update store
watch(
  getFeatureFlags,
  (featureFlags) => {
    store.state.data.featureFlags = featureFlags
    logger.info('[Store] Feature flags updated', { featureFlags })
  },
  { deep: true }
)

export const getStateData = (): OrcasMobileStoreData => {
  return store.state.data
}

export const getStateUser = () => {
  if (!store.state.data.user) {
    throw new Error('[Store] state.data.user is not initialized')
  }
  return store.state.data.user
}

export const getStateCompany = () => {
  if (!store.state.data.company) {
    throw new Error('[Store] state.data.company is not initialized')
  }
  return store.state.data.company
}

export const getStateProviders = () => {
  return store.state.providers
}

export const getStateCallbacks = () => {
  return store.state.callbacks
}

const loadCarrierData = async () => {
  const userCompany = await mothershipService.getCompanyDetails()

  logger.debug(
    '[orcas-mobile-shell - loadState]: User company (mothership) loaded',
    {}
  )

  userCompany.cpsCarrierId =
    await carrierProfilePublicService.exchangeMothershipCarrierIdForNewUUID(
      Number(userCompany.carrierId)
    )

  logger.debug(
    '[orcas-mobile-shell - loadState]: carrierId exchanged to cpsCarrierId',
    {}
  )

  const cpsCarrier = await carrierProfilePublicService.getCarrier(
    userCompany.cpsCarrierId
  )

  logger.debug('[orcas-mobile-shell - loadState]: CPS carrier laoded', {})

  return [userCompany, cpsCarrier] as const
}

const loadCusUser = async () => {
  try {
    const cusUser = await carrierUserService.whoami()

    logger.debug('[orcas-mobile-shell - loadState]: CUS user data loaded', {})

    return cusUser
  } catch (error: any) {
    logger.error(
      `[CUS Service]: Unable to fetch user data, error: ${error.stack}`,
      { error }
    )
    return undefined
  }
}

export const loadState = async () => {
  let userCompany: IUserCompany
  let user: IUser
  let userContact: IUserContact
  let cpsCarrier: Carrier
  try {
    let cusUser: ICUSUser | undefined

    const response = await Promise.all([
      timedFn('loadCarrierData', () => loadCarrierData()),
      timedFn('mothershipService.getProfile', () =>
        mothershipService.getProfile()
      ),
      timedFn('mothershipService.getContactDetails', () =>
        mothershipService.getContactDetails()
      ),
      timedFn('loadCusUser', () => loadCusUser()),
    ])

    ;[[userCompany, cpsCarrier], user, userContact, cusUser] = response

    if (cusUser) {
      user.language = cusUser.info.language || 'en'
      user.hasAcceptedLatestTerms = cusUser.has_accepted_latest_tnc
      if (getStateData().featureFlags['orcas-shell-fetch-user-data-from-cus']) {
        user = mapCUSToMothershipUserData(cusUser)
      }
    }

    logger.debug('[orcas-mobile-shell - loadState]: All user data loaded', {})
  } catch (error: any) {
    logger.error(
      `[orcas-mobile-shell - loadState]: Data can not be loaded, error: ${error.stack}`,
      { error }
    )
    await logout()
    return false
  }

  const hasMarketplaceAccess = getMarketplaceAccess(cpsCarrier)
  const hasOnboardingFormAccess = getOnboardingFormAccess(cpsCarrier)

  store.state.data = {
    type: SharedDataType.ORCAS_MOBILE,
    user: user,
    hasOnboardingFormAccess,
    language: user.language,
    contact: userContact,
    carrier: deserialize(cpsCarrier),
    cpsCarrier,
    company: userCompany,
    gpsIntegration: cpsCarrier.gps_integration,
    featureFlags: getStateData().featureFlags,
    hasMarketplaceAccess,
    isDenylisted: !!cpsCarrier?.is_denylisted,
    hasOrcasAccess: hasMarketplaceAccess,
    hasSennpayAccess: false,
    tenant: getTenant(),
  }

  return true
}

export const setStoreLanguage = async (language: string) => {
  store.state.data.language = language
}

export const clearState = () => {
  store.state.data = getEmptyData()
}

export const getStoreData = () => {
  const data = getStateData()
  if (!data.user) {
    throw new Error(
      '[orcas-mobile-shell - Component Generator]: state.data.user is not initialized'
    )
  }
  if (!data.company) {
    throw new Error(
      '[orcas-mobile-shell - Component Generator]: state.data.company is not initialized'
    )
  }
  if (!data.contact) {
    throw new Error(
      '[orcas-mobile-shell - Component Generator]: state.data.contact is not initialized'
    )
  }
  return data as IOrcasMobileSharedData<OrcasMobileExtendedData>
}

export { store }
