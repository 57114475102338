import {
  CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE,
  CARRIER_PROFILE_PUBLIC_SERVICE_URL,
} from '@/common/config'
import { getAuth0Header } from '@/services/tokenManager'
import {
  Carrier,
  CarrierApi,
  Configuration,
} from '@sennder/carrier-profile-public-axios-client'

class CarrierProfilePublicService {
  private async getApiConfig(): Promise<Configuration> {
    const accessToken = await this.getCPPSToken()
    const options = {
      headers: {
        Authorization: accessToken,
      },
    }
    return new Configuration({
      basePath: CARRIER_PROFILE_PUBLIC_SERVICE_URL,
      baseOptions: options,
    })
  }

  async carrierApiInstance(): Promise<CarrierApi> {
    const apiConfig = await this.getApiConfig()
    return new CarrierApi(apiConfig)
  }

  async getCPPSToken(): Promise<string> {
    const authTokenHeader = await getAuth0Header({
      audience: CARRIER_PROFILE_PUBLIC_SERVICE_AUDIENCE,
    })
    if (!authTokenHeader) {
      throw new Error('[CPPS]: Cant build a request. Token is empty')
    }

    return authTokenHeader
  }

  async getCarrier(carrierId: string): Promise<Carrier> {
    try {
      const api = await this.carrierApiInstance()
      const response = await api.readCarrierV1CarriersCarrierIdGet(carrierId)
      if (response.status !== 200) {
        throw new Error()
      }
      return response.data
    } catch (error: any) {
      throw new Error(`Error getting carrier data from CPPS ${error.stack}`)
    }
  }

  async exchangeMothershipCarrierIdForNewUUID(
    mothershipCarrierId: number
  ): Promise<string> {
    try {
      const api = await this.carrierApiInstance()
      const response =
        await api.mothershipIdToCpsIdV1CarriersIdFromMothershipMothershipIdGet(
          mothershipCarrierId
        )

      return response.data.id
    } catch (error: any) {
      throw new Error(
        `Error exchanging carrier mothership id for uuid CPPS ${
          error.response?.data || error.message
        }`
      )
    }
  }
}

export default new CarrierProfilePublicService()
